

body{
  overflow: hidden
}

.simplebar-content {
  font-size: 0.8em;
}

.small-icon {
  transform: scale(0.7);
}

.card-body {
  padding: 0 !important;
}

.header {
  padding: 0 !important;
}


@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1500px !important;
    padding: 0px !important;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    padding: 0px !important;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    padding: 0px !important;
  }
}

.clickable-drop-id:hover{
  background-color:#3f51b5;
  cursor: pointer;
  border-radius: 10px;
}
.clicked-drop-id{
  background-color:#3f51b5;
  cursor: pointer;
  border-radius: 10px;
}
.tree-table {
  width: 100%;
}

.tree-table th 
 {
  padding: 10px;
  font-size: 13px;
}
.td-track-data {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}
.td-track-small-data {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  white-space: nowrap;
}

.td-track{
  border-right: 4px solid black;
  
}
.td-track::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  background: #ffcb00;
  left: 0.75rem;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
  /* top: -0.55rem; */
}
.font-weight-bold{
  font-weight: bold;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.error-list {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}
.error-list-item {
  font-size: .8em;
}
.error-list-title{
  font-size: .9em;
  font-weight: bold;
}